.App {
  text-align: center;
}
.hide-dropdown .react-tel-input .flag-dropdown {
  display: none !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.moto-spacing-top-large{
  padding-top: 2px;
}

@media only screen and (max-width: 600px) and (min-width: 300px) {
  .mar{
    margin-top: 30px !important;
  }
}

@media only screen and (max-width:990px) and (min-width:580px) {
  .imm{
    width: 100% !important;
    height: 100% !important;

  }
}

@media only screen and (max-width: 2600px) and (min-width: 600px) {
  .media{
    margin-left: 145px !important;
  }
}

.fontlar{
   font-size: 17px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
